import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavBar2 } from "./NavBar2";
import axios from "axios";

interface NavBar2Props {
  isFund?: boolean;
  isBidHistory?: boolean;
  isWinHistory?: boolean;
}

const ADD_FUND_URL = "https://development.smapidev.co.in/api/Api/add_fund";
const token = localStorage.getItem("token") || "";

export const Funds: React.FC<NavBar2Props> = ({
  isFund,
  isBidHistory,
  isWinHistory,
}) => {
  const [selectedPoints, setSelectedPoints] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error" | "";
  }>({ text: "", type: "" });
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [Data, setData] = useState();

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handlePointSelection = (points: number) => {
    setSelectedPoints(points);
    const inputElement = document.getElementById(
      "pointsInput"
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = points.toString();
    }
  };

  const handlePaymentSuccess = () => {
    // After successful payment, update funds
    addFunds();
  };

  const addFunds = async () => {
    if (!selectedPoints) {
      setMessage({ text: "Please select a point amount.", type: "error" });
      return;
    }

    setIsLoading(true);
    setMessage({ text: "", type: "" });

    const randomId = generateRandomId();

    // Create FormData object
    const formData = new FormData();
    formData.append("points", selectedPoints.toString());
    formData.append("trans_status", "successful");
    formData.append("trans_id", randomId);

    try {
      const response = await fetch(ADD_FUND_URL, {
        method: "POST",
        headers: {
          token,
        },
        body: formData,
      });

      const data = await response.json();
      console.log("Fund addition successful:", data);
      setMessage({ text: "Funds added successfully!", type: "success" });
    } catch (error) {
      console.error("Error adding funds:", error);
      setMessage({
        text: "Error adding funds. Please try again.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = () => {
    if (!paymentMethod) {
      setMessage({ text: "Please select a payment method.", type: "error" });
      return;
    }

    if (paymentMethod === "other") {
      handleOpenAllApps(); // Open all apps if "Other" is selected
    } else {
      // Redirect based on selected payment method
      const paymentUrl = generatePaymentUrl(paymentMethod);
      window.location.href = paymentUrl;
    }
  };

  const generatePaymentUrl = (method: string): string => {
    let url = "";
    const mobileNumber = "8618192021"; // Replace with actual mobile number
    const userName = "rakesh saini"; // Replace with actual name
    const transactionNote = "raicoder";
    const currency = "INR";
    
    const playStoreUrls = {
      googlePay: "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user",
      phonePe: "https://play.google.com/store/apps/details?id=com.phonepe.app",
      paytm: "https://play.google.com/store/apps/details?id=com.paytm.android",
    };
  
    switch (method) {
      case "googlePay":
        url = `upi://pay?pa=${mobileNumber}@gpay&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}`;
        break;
      case "phonePe":
        url = `phonepe://pay?pa=${mobileNumber}@phonepe&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}`;
        break;
      case "paytm":
        url = `paytmmp://pay?pa=${mobileNumber}@paytm&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}`;
        break;
      default:
        setMessage({ text: "Invalid payment method.", type: "error" });
        return "";
    }
  
    // Try opening the app; if it fails, redirect to the Play Store
    const fallbackUrl = playStoreUrls[method];
  
    // Attempt to open the app
    window.location.href = url;
  
    // After a delay, check if the app was opened; if not, redirect to Play Store
    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 2000); // Delay to check if the app opens
  
    return url;
  };

  const handleOpenAllApps = () => {
    const mobileNumber = "8618192021"; // Replace with actual mobile number
    const userName = "rakesh saini"; // Replace with actual name
    const transactionNote = "raicoder";
    const currency = "INR";
    
    const urls = [
      `googlepay://pay?pa=${mobileNumber}@gpay&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}`, // Google Pay
      `phonepe://pay?pa=${mobileNumber}@phonepe&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}`, // PhonePe
      `paytmmp://pay?pa=${mobileNumber}@paytm&pn=${userName}&am=${selectedPoints}&tn=${transactionNote}&cu=${currency}` // Paytm
    ];
  
    // Flag to check if any app was opened successfully
    let appOpened = false;
  
    // Attempt to open each app one by one with a 1-second delay
    urls.forEach((url, index) => {
      setTimeout(() => {
        window.location.href = url;
        appOpened = true; // App is launched, no need to check for Play Store
      }, index * 1000); // Opening each app with a 1-second delay
    });
  
    // Fallback to Play Store URLs after a certain period if none of the apps were opened
    setTimeout(() => {
      if (!appOpened) {
        // Only redirect to Play Store if no app opened successfully
        const playStoreUrls = {
          googlePay: "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user",
          phonePe: "https://play.google.com/store/apps/details?id=com.phonepe.app",
          paytm: "https://play.google.com/store/apps/details?id=com.paytm.android",
        };
  
        // Open Play Store URLs for all apps
        window.location.href = playStoreUrls.googlePay;
        setTimeout(() => {
          window.location.href = playStoreUrls.phonePe;
        }, 1000); // Delay for PhonePe
        setTimeout(() => {
          window.location.href = playStoreUrls.paytm;
        }, 2000); // Delay for Paytm
      }
    }, 5000); // 5-second timeout to check if any app was opened
  };



  const fetchAppDetails = async () => {
    try {
      const response = await axios.post(
        'https://development.smapidev.co.in/api/Api/app_details',
        '', // Sending a blank string in the body as specified
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setData(response.data.data.add_fund_notice || " ")
    } catch (error) {
      console.error('Error fetching app details:', error);
    }
  };


  useEffect(() => {
    fetchAppDetails()
  }, [])
  
  return (
    <div>
      <NavBar2 isFund={true} />
      <div
      style={{
        backgroundColor: "#FFF5E4",
        border: "1px solid black",
        borderRadius: "8px",
        padding: "15px",
        textAlign: "center",
        fontWeight: "bold",
        width: "80%",
        maxWidth: "400px",
        margin: "20px auto",
        lineHeight: "1.6",
      }}
    >
      <div style={{ color: "blue", marginBottom: "10px" }}>
        !!Add Fund Notice!!
      </div>
      <div style={{ color: "#FF0066" }}>
     {Data}
        </div>
    </div>
      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="number"
            id="pointsInput"
            className="form-control p-4"
            placeholder="Add Points"
            name="myInput"
            readOnly
            value={selectedPoints || ""}
          />
          <h2 className="text-custom text-left mt-4">Select Point Amount</h2>
          <ul className="flex ml-3 pb-3">
            {[500, 1000, 2000].map((point) => (
              <li key={point}>
                <button
                  type="button"
                  onClick={() => handlePointSelection(point)}
                  className={`btn-funds rounded-full py-2 px-4 text-white text-left flex justify-left mt-4 ${
                    point !== 500 ? "ml-3" : ""
                  } ${selectedPoints === point ? "bg-yellow-500" : "bg-blue-900"}`}
                >
                  {point}
                </button>
              </li>
            ))}
          </ul>
          <ul className="ml-3 pb-3 text-left">
           
            <li className="mt-2 text-custom">
              <input
                type="radio"
                name="paymentMethod"
                value="phonePe"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />{" "}
              Phone Pe
            </li>
            <li className="mt-2 text-custom">
              <input
                type="radio"
                name="paymentMethod"
                value="paytm"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />{" "}
              Paytm
            </li>
            <li className="mt-2 text-custom">
              <input
                type="radio"
                name="paymentMethod"
                value="googlePay"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />{" "}
              Google Pay
            </li>
            {/* Option for "Other" */}
            <li className="mt-2 text-custom">
              <input
                type="radio"
                name="paymentMethod"
                value="other"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />{" "}
              Other (Open all apps)
            </li>
            <li>
              <button
                type="button"
                className="btn-funds rounded-lg custom-color py-2 px-4 text-white flex justify-center mt-4 ml-3 mx-auto w-100 text-center"
                onClick={handlePayment}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Pay"}
              </button>
            </li>
          </ul>
        </form>
        {message.text && (
          <div className={`alert alert-${message.type}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};
